$white: #ffffff;
$lightBlue: #61646a;
$blue: #223266;
$blue2: #2138b2;
$blue3: #0075e2;
$darkBlue: #3c3c3b;
$darkBlue2: #1b2852;
$darkBlue3: #1f3a6f;
$lightGrey: #e5e5e5;
$lightGrey2: #e9ebf0;
$lightGrey3: #f6f6fa;
$lightGrey4: #f8f8fa;
$lightGrey5: #f1f1f1;
$lightGrey6: #f6f7f7;
$grey: #9ca0aa;
$grey2: #cccfd8;
$grey3: #e9ecef;
$green: #078a3c;
$greenLight: #cde8d8;
$darkGrey: #626770;
$darkGrey2: #2979ff0a;
$darkGrey3: #1b1b2c14;
$darkGrey4: #10141fb2;
$darkPurple: #647094;
$darkYellow: #eccb00;
$redLight: #eb041233;
$lightYellow: #fbf5cc;
$red: #dc3545;
