@import '../constants/colors';

.login-form {
  background-color: $white;
  padding: 32px 16px;
  flex: 1 1 45%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .submissions {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $lightGrey2;

    .request-form-title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 105px;
      padding: 8px 0;
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
      color: #172345;
      background: #f6f6fa;

      &:hover,
      &:active {
        background: #f0f0f6;
      }

      @media (max-width: 767px) {
        min-height: initial;
      }
    }

    .eaf-link {
      background: $lightGrey3;
      padding: 13px 20px;
      border-radius: 4px;
      text-decoration: none;
      color: #172345;
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;

      .arrow {
        background-image: url('../../images/right-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 16px;
      }
    }
  }

  .login-logotypes {
    display: flex;
    justify-content: center;
    align-items: center;

    .csnri-logo {
      background: url('../../images/CSNRI-full-logo.png') no-repeat center top;
      background-size: cover;
      height: 71px;
      width: 200px;
    }

    .loctite-logo {
      background: url('../../images/Loctite-logo.png') no-repeat center;
      background-size: 175px 33px;
      height: 60px;
      width: 200px;
    }
  }

  header {
    background: url('../../images/logo174x75.png') no-repeat center top;
    min-height: 75px;
    width: 100%;
  }

  h1 {
    text-align: center;
  }

  .forgot-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    a {
      color: $blue2;
    }
  }

  .setup-new-password-wrapper,
  .reset-password-success-wrapper,
  .reset-password-wrapper,
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 430px;
  }

  .reset-password-success-wrapper,
  .reset-password-wrapper {
    .back-link {
      text-decoration: none;
      font-size: 12px;
      color: $blue2;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .info-wrapper {
      margin-top: 16px;

      p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  .setup-new-password-wrapper {
    align-items: center;

    > form > fieldset > legend {
      display: none;
    }
  }

  .reset-password-success-wrapper {
    align-items: center;
    height: 450px;

    h1 {
      margin-top: 19px;
    }
  }

  .reset-password-wrapper {
    height: 550px;

    h1 {
      margin-top: 72px;
    }
  }

  footer {
    a {
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color: $grey;
      margin: 0 23px;
    }
  }

  .btn {
    align-self: center;
    margin-top: 20px;
    width: 100%;
    border-radius: 4px;
  }

  .logged-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    background: $lightYellow;
    border-radius: 4px;
    margin: 20px auto 0;
  }

  .warning-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    background: $redLight;
    border-radius: 4px;
    margin: 20px auto 0;
  }

  .password-wrapper {
    position: relative;

    .eye-crossed-out-icon,
    .eye-icon {
      cursor: pointer;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  #activate_user {
    > fieldset > legend {
      display: none;
    }
  }

  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: $darkBlue;
  }

  .description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $lightBlue;
    margin-top: 16px;
  }

  .password-helper {
    margin-top: 12px;

    .display-flex {
      margin-top: 12px;
      display: flex;

      .empty-icon {
        border: 1px solid $grey;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin-right: 12px;
      }
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    margin-top: 26px;

    .form-check-input {
      margin-top: 0;
      width: 18px;
      height: 18px;
    }

    label {
      margin-top: 0;
      margin-left: 11px;
    }
  }
}

.login-background {
  background-image: url('../../images/background_loginPage.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  flex: 1 1 55%;
  height: 100vh;
}
